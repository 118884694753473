@media screen and (min-width: 600px) {
  .project-page {
    width: 80%;
    margin-left: 10%;
  }

  .markdown iframe {
    width: 100%;
    height: calc(80vw * (9/16))
  }
}

@media screen and (max-width: 900px) {
  .project-page {
    width: 90%;
    margin-left: 5%;
  }

  .markdown iframe {
    width: 100%;
    height: calc(90vw * (9/16))
  }
}

@media screen and (min-width: 900px) {
  .project-page {
    width: 60%;
    margin-left: 20%;
  }

  .markdown iframe {
    width: 100%;
    height: calc(60vw * (9/16))
  }
}

.project-page img {
  width: 100%;
  height: auto;

  margin-top: 15px;
  margin-bottom: 15px;
}

.title-row {
  margin-top: 30px;

  display: flex;
  align-items: center;
}

.title-row > .title {
  margin-top: 25px;
  
  color: #ef6c00;
  font-family: Roboto;
  font-size: 26px;
}

.project-page p {
  font-family: "Roboto";
  font-size: 16px;

  justify-content: center;
  text-align: left;
}

.project-page .with {
  margin-bottom: 4px;
}

.with a {
  color: #00897B;
}

.project-page .technologies {
  margin-top: 4px;
}

.project-page .technologies span {
  color: #00897B;
}

.project-page .back {
  width: 30px;
  height: 30px;

  margin-right: 10px;
  fill: #ef6c00;

  cursor: pointer;
}
