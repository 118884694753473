.project-container {
	display: flex;
	flex-direction: row;

	margin-bottom: 10px;
}

.project-container.left > *:first-child {
	margin-right: 20px;
}

.project-container.right > *:last-child {
	margin-right: 20px;
}

.project-container.right {
	flex-direction: row-reverse;
}

.project-image {
	max-height: 300px;
	max-width: 350px;
	object-fit: cover;
	border-radius: 5px;

	/* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
}

.project-details {
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: space-between;	
}

.project-details h2 {
	--title-margin-top: 0px;

	font-family: "Roboto";
	color: #ef6c00;

	display: inline-block;
	cursor: pointer;

	margin-top: var(--title-margin-top);
	margin-bottom: 0px;
}

.description-container {
	--description-height: auto;
	height: var(--description-height);

	position: relative;
}

.project-description {
	font-family: "Roboto";
	font-size: 18px;
	line-height: 22px;
	color: #424242;
	font-weight: 400;

	white-space: pre-wrap;
	overflow: hidden;

	height: var(--description-height);
}

.fade {
	--fade-width: 30%;

	position: relative;
	height: var(--description-height);
}

.fade:after {
	content: "";
	text-align: right;
	position: absolute;
	bottom: 0;
	right: 0;
	width: var(--fade-width);
	height: 24px;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.read-more {
	position: absolute;
	right: 0;
	bottom: 4px; 

	height: 20px;
	padding: 2px 0;

	font-family: "Roboto";
	font-size: 12px;
	color: black;

	background: transparent;
	border: none;
	border-bottom: 1px solid #ef6c00;

	cursor: pointer;
}

.read-more:hover {
	border-bottom: 2px solid #ef6c00;
}

.project-caption {
	margin-top: 20px;
	margin-bottom: 10px;
}

.project-caption > p {
	height: 25px;

	font-family: "Roboto";
	font-size: 16px;
	color: #424242;
	font-weight: 400;

	margin-bottom: 0px;
	margin-top: 0;
}

.project-caption span {
	color: #009688;
}

.divider {
	height: 1px;
	width: 100%;

	/* background-color: #bdbdbd; */
	margin-bottom: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;

  padding-left: 10px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  
  background-color: #BDBDBD;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  bottom: 100%;
  left: 50%;
  margin-left: -50%;

  font-family: "Roboto";
  font-size: 12px;
  white-space: nowrap;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 767px) {

	.project-container.right {
		flex-direction: column;
	}

	.project-details h2 {
		--title-margin-top: 10px;
	}

	.fade {
		--fade-width: 45%;
	}
	
}

