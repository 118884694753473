.home {
	background-color: white;

	display: flex;
	flex-direction: column;
	min-height: 100vh;

	width: 70%;
	margin: auto;
}

.home > h1 {
	font-family: "Roboto";
	color: #616161;

	margin-top: 100px;
}

.home > .name {
	font-family: "Roboto";
	color: #ef6c00;
}

.home > .work-header {
	font-family: "Roboto";
	color: #ef6c00;
	/* font-size: 1.7em; */

	margin-top: 20px;
	margin-bottom: 0;
}

.description > p {
	font-family: "Roboto";
	font-size: 18px;
	line-height: 22px;
	color: #424242;
	font-weight: 400;

	/* margin-top: 8px; */
	/* margin-bottom: 8px; */
}

.control-div {
	margin-top: 5px;
	margin-bottom: 20px;

	/* height: 60px; */
}

.projects-list {
	padding-bottom: 50px;
}

.orange-link:link, .orange-link:visited {
    color: #ef6c00;
    text-decoration: none;
}

.orange-link:focus, .orange-link:hover {
    color: #ef6c00;
    text-decoration: none;
}

.orange-link:focus {
    text-decoration: none;
}

/*
 * Search input
 */

 .search-input {
	height: 40px;

	font-family: "Roboto";
	font-size: 16px;

	border: none;
	border-bottom: 1px solid #9e9e9e;
 }

 .search-input:focus { 	
	outline: none;
	border-bottom: 2px solid #ef6c00;
 }

 .filter-hint {
	display: inline-block;

	font-family: "Roboto";
	font-size: 16px;
	color: #424242;

	margin-right: 10px;
 }

 .contact-row {
 	margin-top: 30px;
 	margin-bottom: 30px;

 	font-family: "Open Sans";
 	font-size: 16px;

 	color: #78909C;
 }

.contact-row a, .contact-row a:visited, .contact a:link {
 	color: black;
 	text-decoration: none;
 }

 /*
	* Overlay
	*/

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	pointer-events: none;
	background-color: white;
	opacity: 0;
}

.overlay-title {
	position: absolute;

	font-family: "Roboto";
	color: #ef6c00;
	font-size: 3vh;

	width: 100%;
	text-align: center;
	/*height: auto;*/

	display: inline-block;

	margin-top: 0px;
	margin-bottom: 0px;

	/*left: 50%;*/
	top: 50%;
}

@media (max-width: 767px) {
  
  .home {
  	width: 90%;
  }

  .home > h1 {
		margin-top: 30px;
	}
  
}

.technology {
	/* color: #009688; */
	font-style: italic;
}

.client {
	color: #ef6c00;
}