/*
 * Pills
 */

.pill {
	--pill-height: 40px;
	--pill-padding: 25px;
	--pill-margin: 10px;
	--pill-margin-selected: 9px;

	height: var(--pill-height);
	width: auto;
	padding: 0 var(--pill-padding);
	margin: 0 var(--pill-margin);
	box-sizing: border-box;

	font-family: "Roboto";
	font-size: 14px;
	/*font-weight: 300;*/

	text-align: center;

	color: #424242;
	background-color: white;
	border: none;
	border-radius: 20px;
	border: 1px solid #9e9e9e;

	cursor: pointer;

	/* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

.pill:focus {
	outline: none;
}

/* .shadow {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
} */

.pill.selected {
	/* transform: translateY(-5px); */
	
	border: 1px solid #ef6c00;
	background-color: #ff953e;
	color: white;

	font-weight: 600;
	
	margin: 0 var(--pill-margin-selected);
}

@media (max-width: 767px) {

	.pill {
		--pill-height: 30px;
		--pill-padding: 15px;
		--pill-margin: 5px;
		--pill-margin-selected: 4px;
	}
	
}

@media (min-width: 600px) {
	
	.pill:hover {
		border: 1px solid #ef6c00;
	}

	/* .shadow:hover {
		box-shadow: 0 3px 7px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
		transform: translateY(-5px);
	} */
}